<form *ngIf="adminPreview || (!submissionComplete && adminForm.value.active)" class="mt-4 mt-lg-5 pb-4 d-block" [ngClass]="{'was-validated': showValidation}" [formGroup]="submissionForm" novalidate>
  <ng-container formArrayName="fields" *ngFor="let adminField of adminFields.controls | sortByIndex; let i = index">


    <!-- Headline -->
    <div *ngIf="adminField.value.type === 'content-headline'">
      <h4 class="mt-4 mt-lg-5 mb-2 mb-lg-3 text-center form-heading">{{adminField.value.content}}</h4>
    </div>


    <!-- Copy -->
    <div *ngIf="adminField.value.type === 'content-copy'">
      <div class="content-container text-center" [innerHTML]="sanitizer.bypassSecurityTrustHtml(adminField.value.content)"></div>
    </div>


    <!-- Copy -->
    <div *ngIf="adminField.value.type === 'content-video'">
      <div class="yt-preview mt-2">
        <youtube-player videoId="{{youtubeId(adminField.value.content)}}"/>
      </div>
    </div>


    <!-- Divider -->
    <hr *ngIf="adminField.value.type === 'content-divider'">


    <!-- Plain Text -->
    <div class="form-group clearfix mw720" *ngIf="adminField.value.type === 'plain-text'" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3" for="plainText{{i}}">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <input
        type="text"
        class="form-control"
        id="plainText{{i}}"
        [attr.maxLength]="adminField.value.maximumCharacters ?? null"
        [attr.minLength]="adminField.value.minimumCharacters ?? null"
        [attr.required]="adminField.value.required ? '' : null"
        formControlName="value">

      <span class="text-muted float-right" *ngIf="adminField.value.minimumCharacters || adminField.value.maximumCharacters">
        <span [ngClass]="{
          'text-danger !important': !!adminField.value.minimumCharacters && !submissionFields.at(i).pristine
            ? submissionFields.at(i).value.value?.length < adminField.value.minimumCharacters
            : false
        }">{{submissionFields.at(i).value.value?.length || 0}}</span>
        <span *ngIf="adminField.value.maximumCharacters">/{{adminField.value.maximumCharacters}}</span>
        <span *ngIf="!!adminField.value.minimumCharacters">&nbsp;({{adminField.value.minimumCharacters}} minimum)</span>
      </span>
    </div>


    <!-- Rich Text -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'richText'" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>
      <ckeditor
        [config]="standardEditorConfig"
        [editor]="Editor"
        formControlName="value"
      ></ckeditor>
    </div>


    <!-- Number -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'number' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3" for="number{{i}}">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <div class="input-group">
        <div class="input-group-prepend" *ngIf="!!adminField.value.prefix">
          <span class="input-group-text">
            {{adminField.value.prefix}}
          </span>
        </div>
        <input
          type="number"
          formControlName="value"
          class="form-control"
          id="number{{i}}"
          [attr.step]="adminField.value.step"
          [attr.max]="adminField.value.maximum ?? null"
          [attr.min]="adminField.value.minimum ?? null"
          [attr.required]="adminField.value.required ? '' : null"
          >
        <div class="input-group-append" *ngIf="adminField.value.suffix">
          <span class="input-group-text">{{adminField.value.suffix}}</span>
        </div>
        <div class="invalid-feedback">
          <span *ngIf="!!adminField.value.minimum">Must be greater than {{adminField.value.minimum}}. </span>
          <span *ngIf="!!adminField.value.maximum">Must be less than {{adminField.value.maximum}}. </span>
        </div>
      </div>
    </div>


    <!-- URL -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'url' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3" for="url{{i}}">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>
      <input
        class="form-control"
        formControlName="value"
        type="url"
        id="url{{i}}"
        [attr.required]="adminField.value.required ? '' : null">
    </div>


    <!-- Date -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'date' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3" for="date{{i}}">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <input
        *ngIf="!adminField.value.range"
        class="form-control"
        formControlName="value"
        [type]="adminField.value.includeTime ? 'datetime-local' : 'date'"
        [attr.required]="adminField.value.required ? '' : null"
        id="date{{i}}">


      <!-- Range -->
      <div class="row" *ngIf="adminField.value.range" formGroupName="value">
        <div class="form-group col-md">
          <label for="date{{i}}-start">
            Start {{adminField.value.includeTime ? 'Time' : 'Date'}}
          </label>

          <input
            type="text"
            class="form-control"
            id="date{{i}}-start"
            formControlName="start"
            [type]="adminField.value.includeTime ? 'datetime-local' : 'date'"
            [attr.required]="adminField.value.required ? '' : null">
        </div>

        <div class="form-group col-md">
          <label for="date{{i}}-end">
            End {{adminField.value.includeTime ? 'Time' : 'Date'}}
          </label>

          <input
            type="text"
            class="form-control"
            id="date{{i}}-end"
            formControlName="end"
            [type]="adminField.value.includeTime ? 'datetime-local' : 'date'"
            [attr.required]="adminField.value.required ? '' : null">
        </div>
      </div>
    </div>


    <!-- Email -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'email' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3" for="email{{i}}">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <input
        class="form-control"
        formControlName="value"
        type="email"
        id="email{{i}}"
        [attr.required]="adminField.value.required ? '' : null">
      <em class="pl-2">*Receive Email Updates</em>
    </div>


    <!-- Checkbox -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'checkbox' && !adminField.value.hidden">
      <label class="font-weight-bold mt-lg-3">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <div class="pl-2">
        <div class="form-check" *ngFor="let option of fieldOptions(adminField).controls | sortByIndex; let oi = index">
          <input
            type="checkbox"
            class="form-check-input"
            id="checkbox{{i}}option{{oi}}"
            [checked]="option.value.default"
            [value]="option.value.id"
            (change)="updateCheck($event, i)">
          <label class="form-check-label" for="checkbox{{i}}option{{oi}}">{{option.value.value}}</label>
        </div>
      </div>
    </div>


    <!-- Agreement -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'agreement' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <div class="pl-2">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="agreement{{i}}"
            formControlName="value"
            [value]="true">
          <label class="form-check-label" for="agreement{{i}}">{{adminField.value.text}}</label>
        </div>
      </div>
    </div>


    <!-- Radio -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'radio' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <div class="pl-2">
        <div class="form-check" *ngFor="let option of fieldOptions(adminField).controls | sortByIndex; let oi = index">
          <input
            class="form-check-input"
            type="radio"
            id="radio{{i}}option{{oi}}"
            formControlName="value"
            [value]="option.value.value">
          <label class="form-check-label" for="radio{{i}}option{{oi}}">{{option.value.value}}</label>
        </div>

        <a
          *ngIf="!adminFields.value.required"
          href="javascript:void(0);"
          class="ml-3"
          (click)="submissionFields.at(i).patchValue({value: null})"
        >Reset</a>
      </div>
    </div>


    <!-- Contact -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'contact' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-lg-3">{{adminField.value.prompt}}</label>

      <ng-container formGroupName="value">

        <!-- Primary -->
        <ng-container
          *ngTemplateOutlet="
            contactTemplate;
            context: {
              key: 'primary',
              admin: adminField.get('primary'),
              submission: submissionFields.at(i).get('value.primary'),
            }"
        ></ng-container>

        <!-- Billing -->
        <ng-container
          *ngTemplateOutlet="
            contactTemplate;
            context: {
              key: 'billing',
              admin: adminField.get('billing'),
              commonInputsIndicated: adminField.get('commonInputsIndicated').value,
              submission: submissionFields.at(i).get('value.billing'),
              primaryEnabled: adminField.get('primary.enabled').value,
            }"
        ></ng-container>

        <!-- Shipping -->
        <ng-container
          *ngTemplateOutlet="
            contactTemplate;
            context: {
              key: 'shipping',
              admin: adminField.get('shipping'),
              commonInputsIndicated: adminField.get('commonInputsIndicated').value,
              submission: submissionFields.at(i).get('value.shipping'),
              primaryEnabled: adminField.get('primary.enabled').value,
              billingEnabled: adminField.get('billing.enabled').value ,
            }"
        ></ng-container>

      </ng-container>
    </div>


    <!-- Donation -->
    <div class="form-group mw720" *ngIf="adminField.value.type === 'donation' && !adminField.value.hidden" [formGroupName]="i">
      <label class="font-weight-bold mt-2 mt-lg-3">
        <span *ngIf="adminField.value.required">*</span>{{adminField.value.prompt}}
      </label>

      <div>
        <div class="form-check form-check-inline" *ngIf="adminField.value.showOneTime">
          <input
            type="radio"
            class="form-check-input"
            id="donation-once"
            formControlName="type"
            [value]="1"
            (change)="updateDonationType(i)">
          <label class="form-check-label" for="donation-once">One Time</label>
        </div>
        <div class="form-check form-check-inline" *ngIf="adminField.value.showMonthly">
          <input
            type="radio"
            class="form-check-input"
            id="donation-monthly"
            formControlName="type"
            [value]="2"
            (change)="updateDonationType(i)">
          <label class="form-check-label" for="donation-monthly">Monthly</label>
        </div>
        <div *ngIf="!adminField.value.required" class="form-check form-check-inline">
          <input
            type="radio"
            class="form-check-input"
            id="donation-none"
            formControlName="type"
            [value]="0"
            (change)="updateDonationType(i)">
          <label class="form-check-label" for="donation-none">None</label>
        </div>
      </div>

      <div class="mt-2" *ngIf="submissionFields.at(i).value.type !== 0" formGroupName="value">
        <!-- One Time Options -->
        <ng-container *ngIf="submissionFields.at(i).value.type === 1">
          <div class="form-check form-check-inline" *ngFor="let option of oneTimeDonations(adminField).controls | sortByIndex; let oi = index">
            <input
              class="form-check-input"
              type="radio"
              id="donationOnce{{oi}}"
              [value]="option.value.value"
              formControlName="amount"
              (change)="removeCustomAmount()">
            <label class="form-check-label" for="donationOnce{{oi}}">${{option.value.value}}</label>
          </div>
        </ng-container>

        <!-- Monthly Options -->
        <ng-container *ngIf="submissionFields.at(i).value.type === 2">
          <div class="form-check form-check-inline" *ngFor="let option of monthlyDonations(adminField).controls | sortByIndex; let oi = index">
            <input
              class="form-check-input"
              type="radio"
              id="donationMonthly{{oi}}"
              [value]="option.value.value"
              formControlName="amount"
              (change)="removeCustomAmount()">
            <label class="form-check-label" for="donationMonthly{{oi}}">${{option.value.value}}</label>
          </div>
        </ng-container>

        <!-- Custom Donation Input -->
        <div class="form-check form-check-inline position-relative">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <label class="input-group-text">
                <input
                  type="radio"
                  class="form-check-input"
                  id="donationCustom"
                  [value]="-1"
                  (click)="focusOnCustom()"
                  formControlName="amount">
                Custom: $
              </label>
            </div>
            <input
              min="5"
              id="customDonationInput"
              type="number"
              class="form-control"
              [ngClass]="{'border-danger':
                submissionFields.at(i).value.value.amount === -1 &&
                (!customDonationAmount || customDonationAmount < 5)
              }"
              [(ngModel)]="customDonationAmount"
              [ngModelOptions]="{standalone: true}"
              (change)="submissionFields.at(i).updateValueAndValidity()"
              (click)="selectCustomDonation(i)"
              >
              <small class="text-secondary position-absolute font-italic" style="top: 100%; right: 0;">$5 minimum</small>
          </div>
        </div>

        <!-- Monthly Options -->
        <div *ngIf="submissionFields.at(i).value.type === 2">
          <label class="mt-3">To be donated on the
            <select class="custom-select custom-select-sm" formControlName="donationDate" style="width: auto;">
              <ng-container *ngFor="let x of dayOptions">
                <option [ngValue]="x+1">{{ ordinal(x+1) }}</option>
              </ng-container>
            </select>
            day of the month.
          </label>

          <div class="form-check mt-1">
            <input
              type="checkbox"
              class="form-check-input"
              id="donation-chargeNow"
              formControlName="donateNow"
              [value]="true">
            <label class="form-check-label" for="donation-chargeNow">Donate today as well!</label>
          </div>
        </div>

        <div class="mt-3">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="paymentType"
              id="donation-type-cc"
              value="cc"
              (change)="updateButtonType()">
            <label class="form-check-label" for="donation-type-cc">Credit Card</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="paymentType"
              id="donation-type-ach"
              value="ach"
              (change)="updateButtonType()">
            <label class="form-check-label" for="donation-type-ach">ACH</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="paymentType"
              id="donation-type-paypal"
              value="paypal"
              (change)="updateButtonType()">
            <label class="form-check-label" for="donation-type-paypal">Paypal</label>
          </div>
        </div>



        <!-- CC Iframe -->
        <div class="card payment-section mt-2" *ngIf="submissionFields.at(i).value.value.paymentType === 'cc'">
          <div class="card-body p-2">
            <label class="mb-0" for="cc-input1"><small>*Name on Card</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="name"
              type="text"
              id="cc-input1">

            <label class="mb-0 mt-1" for="cc-input2"><small>*Card Number</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="account"
              type="text"
              id="cc-input2"
              maxlength="16">

            <div class="row">
              <div class="col-4">
                <label class="mb-0 mt-1" for="cc-input3"><small>*CVV</small></label>
                <input
                  class="form-control form-control-sm"
                  formControlName="verificationCode"
                  type="text"
                  id="cc-input3"
                  maxlength="4">
              </div>
              <div class="col-8">
                <label class="mb-0 mt-1" for="cc-input4"><small>*Expires</small></label>
                <div class="input-group input-group-sm">
                  <select class="custom-select" id="cc-input4MM" formControlName="expiryMM">
                    <option selected disabled [value]="null">MM</option>
                    <option value="01">01 (JAN)</option>
                    <option value="02">02 (FEB)</option>
                    <option value="03">03 (MAR)</option>
                    <option value="04">04 (APR)</option>
                    <option value="05">05 (MAY)</option>
                    <option value="06">06 (JUN)</option>
                    <option value="07">07 (JUL)</option>
                    <option value="08">08 (AUG)</option>
                    <option value="09">09 (SEP)</option>
                    <option value="10">10 (OCT)</option>
                    <option value="11">11 (NOV)</option>
                    <option value="12">12 (DEC)</option>
                  </select>
                  <select class="custom-select" id="cc-input4MM" formControlName="expiryYYYY">
                    <option selected disabled [value]="null">YYYY</option>
                    <option
                      *ngFor="let i of [0,1,2,3,4,5,6,7,8]"
                      [value]="currentYear + i">
                      {{currentYear + i}}
                    </option>
                  </select>
                </div>

              </div>
            </div>

            <label class="mb-0 mt-1" for="cc-input5"><small>*Postal Code</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="postalCode"
              type="text"
              id="cc-input5">


          </div>
        </div>



        <!-- ACH Iframe -->
        <div class="card payment-section mt-2" *ngIf="submissionFields.at(i).value.value.paymentType === 'ach'">
          <div class="card-body p-2">
            <label class="mb-0" for="ach-input1"><small>*Name on Account</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="name"
              type="text"
              id="ach-input1">

            <label class="mb-0 mt-1" for="ach-input2"><small>*Account Number</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="account"
              type="text"
              id="ach-input2">

            <label class="mb-0 mt-1" for="ach-input3"><small>*Routing Number</small></label>
            <input
              class="form-control form-control-sm"
              formControlName="routing"
              type="text"
              id="ach-input3"
              minlength="9"
              maxlength="9">

            <label class="mb-0 mt-1" for="ach-input4"><small>*Account Type</small></label>
            <select class="custom-select" id="ach-input4" formControlName="accountType">
              <option selected disabled>Choose Account Type</option>
              <option value="ECHK">Checking</option>
              <option value="ESAV">Savings</option>
            </select>
          </div>
        </div>




      </div>
    </div>
  </ng-container>



  <!-- Buttons -->

  <div class="text-center mw720 mt-4 mt-lg-5">
    <div [ngClass]="{'d-none': !showPaypalButton}" class="position-relative">
      <div id="paypal-container" [ngClass]="{'d-none': !submissionForm.valid || blockPaypalMonthly}"></div>

      <div class="paypal-blocker mb-4" [ngClass]="{'d-none': submissionForm.valid && !blockPaypalMonthly}">
        <i class="fa-brands fa-3x fa-paypal" style="color: #ffc439; text-shadow: 2px 2px 0px #019cde;"></i>

        <div *ngIf="blockPaypalMonthly">Monthly donations through Paypal are currently unavailable.</div>
      </div>

    </div>

    <ng-container *ngIf="!showPaypalButton">
      <button
        type="button"
        class="btn btn-primary"
        (click)="initSubmit()"
        [disabled]="!submissionForm.valid || adminPreview"
        [attr.title]="adminPreview ? 'Submission disabled for admin preview.' : null">
        {{!!adminForm.value.submitText ? adminForm.value.submitText : 'Submit'}}
      </button>
      <!-- <button class="btn btn-secondary" (click)="log()" type="button">Log</button> -->
    </ng-container>
  </div>
</form>



<!-- Contact Template -->
<ng-template
  #contactTemplate
  let-admin="admin"
  let-inputs="admin.get('fields').controls"
  let-submission="submission"
  let-key="key"
  let-primaryEnabled="primaryEnabled"
  let-billingEnabled="billingEnabled"
  let-commonInputsIndicated="commonInputsIndicated">

  <div *ngIf="admin.value.enabled" class="container p-0 mt-3">
    <ng-container [formGroup]="submission">
      <strong class="text-muted">{{camelCaseLegible(key)}} Contact</strong>

      <!-- 'Same As' Checkbox -->
      <ng-container *ngIf="commonInputsIndicated">
        <div *ngIf="billingEnabled" class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            value="true"
            formControlName="copyBilling"
            id="contact-copy-type-{{key}}"
            (change)="updateContactRequirements(submission, admin.value)">
          <label class="form-check-label" for="contact-copy-type-{{key}}">
            Same As Billing
          </label>
        </div>
        <div *ngIf="!billingEnabled && primaryEnabled" class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            value="true"
            formControlName="copyPrimary"
            id="contact-copy-type-{{key}}"
            (change)="updateContactRequirements(submission, admin.value)">
          <label class="form-check-label" for="contact-copy-type-{{key}}">
            Same As Primary
          </label>
        </div>
      </ng-container>

      <ng-container *ngIf="!submission.value.copyBilling && !submission.value.copyPrimary">

        <!-- Individual/Org -->
        <div class="form-group">
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="contact-input-org-{{key}}-f"
              [value]="false"
              formControlName="isOrg"
              (change)="updateContactRequirements(submission, admin.value)">
            <label class="form-check-label" for="contact-input-org-{{key}}-f">Individual</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="contact-input-org-{{key}}"
              [value]="true"
              formControlName="isOrg"
              (change)="updateContactRequirements(submission, admin.value)">
            <label class="form-check-label" for="contact-input-org-{{key}}">Organization</label>
          </div>
        </div>

        <!-- Name Input -->
        <ng-container *ngIf="inputs.name.value.display">
          <!-- Individual Name -->
          <div class="row" *ngIf="!submission.value.isOrg">
            <div class="form-group col-md">
              <label for="contact-input-{{key}}-fname">
                <span *ngIf="inputs.name.value.required">*</span>First Name
              </label>

              <input
                type="text"
                class="form-control"
                id="contact-input-{{key}}-fname"
                formControlName="firstName">
            </div>

            <div class="form-group col-md">
              <label for="contact-input-{{key}}-lname">
                <span *ngIf="inputs.name.value.required">*</span>Last Name
              </label>

              <input
                type="text"
                class="form-control"
                id="contact-input-{{key}}-lname"
                formControlName="lastName">
            </div>
          </div>

          <!-- Org Name -->
          <div *ngIf="submission.value.isOrg" class="form-group">
            <label for="contact-input-{{key}}-orgname">
              <span *ngIf="inputs.name.value.required">*</span>Organization Name
            </label>

            <input
              type="text"
              class="form-control"
              id="contact-input-{{key}}-orgname"
              formControlName="orgName">
          </div>
        </ng-container>

        <div class="row">
          <!-- Email -->
          <div *ngIf="inputs.email.value.display" class="form-group col-md">
            <label for="contact-input-{{key}}-email">
              <span *ngIf="inputs.email.value.required">*</span>Email
            </label>
            <input
              type="email"
              class="form-control"
              id="contact-input-email"
              formControlName="email">
            <em class="pl-2">*Receive Email Updates</em>
          </div>

          <!-- Phone -->
          <div *ngIf="inputs.phone.value.display" class="form-group col-md" formGroupName="phone">
            <label for="contact-input-{{key}}-phone">
              <span *ngIf="inputs.phone.value.required">*</span>Phone
            </label>

            <input
              type="tel"
              class="form-control"
              id="contact-input-{{key}}-phone"
              formControlName="number">

            <div class="form-check form-check-inline pl-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="contact-input-{{key}}-phone-check"
                [value]="true"
                formControlName="messagingAllowed">
              <label class="form-check-label" for="contact-input-{{key}}-phone-check">Receive SMS updates</label>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Birthday -->
          <div *ngIf="inputs.birthday.value.display && !submission.value.isOrg" class="form-group col-md">
            <label for="contact-input-{{key}}-bday">
              <span *ngIf="inputs.birthday.value.required">*</span>Birthday
            </label>

            <input
              type="date"
              class="form-control"
              id="contact-input-{{key}}-bday"
              formControlName="birthday">
          </div>

          <!-- Gender -->
          <ng-container *ngIf="inputs.gender.value.display"></ng-container>
          <div *ngIf="inputs.gender.value.display && !submission.value.isOrg" class="form-group col-md">
            <label for="contact-input-{{key}}-gender">
              <span *ngIf="inputs.gender.value.required">*</span>Gender
            </label>

            <div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  id="contact-input-{{key}}-gender-m"
                  value="male"
                  formControlName="gender">
                <label class="form-check-label" for="contact-input-{{key}}-gender-m">Male</label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  id="contact-input-{{key}}-gender"
                  value="female"
                  formControlName="gender">
                <label class="form-check-label" for="contact-input-{{key}}-gender">Female</label>
              </div>
            </div>
          </div>
        </div>


        <!-- Street Address -->
        <div *ngIf="inputs.streetAddress.value.display" class="form-group">
          <label for="contact-input-{{key}}-street1">
            <span *ngIf="inputs.streetAddress.value.required">*</span>Street Address
          </label>

          <input
            type="text"
            class="form-control mb-1"
            id="contact-input-{{key}}-street1"
            formControlName="streetAddress"
            placeholder="Address Line 1">

          <input
            type="text"
            class="form-control"
            id="contact-input-{{key}}-street2"
            formControlName="streetAddress2"
            placeholder="Address Line 2">
        </div>


        <!-- City -->
        <div class="row">
          <div *ngIf="inputs.city.value.display" class="form-group col-md">
            <label for="contact-input-{{key}}-city">
              <span *ngIf="inputs.city.value.required">*</span>City
            </label>
            <input
              type="text"
              class="form-control"
              id="contact-input-{{key}}-city"
              formControlName="city">
          </div>

          <!-- State -->
          <div *ngIf="inputs.state.value.display" class="form-group col-md">
            <label for="contact-input-{{key}}-state">
              <span *ngIf="inputs.state.value.required">*</span>State
            </label>
            <input
              type="text"
              class="form-control"
              id="contact-input-{{key}}-state"
              formControlName="state">
          </div>

          <!-- Postal -->
          <div *ngIf="inputs.postalCode.value.display" class="form-group col-md">
            <label for="contact-input-{{key}}-postalCode">
              <span *ngIf="inputs.postalCode.value.required">*</span>Postal Code
            </label>
            <input
              type="text"
              class="form-control"
              id="contact-input-{{key}}-postalCode"
              formControlName="postalCode">
          </div>
        </div>

        <!-- Country -->
        <div *ngIf="inputs.country.value.display" class="form-group">
          <label for="contact-input-{{key}}-country">
            <span *ngIf="inputs.country.value.required">*</span>Country
          </label>
          <input
            type="text"
            class="form-control"
            id="contact-input-{{key}}-country"
            formControlName="country">
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>










<!-- Success -->
<div *ngIf="submissionComplete" class="mt-4 pb-4 d-block">
  <h4>Success</h4>
  <div class="content-container" [innerHTML]="submissionData.message"></div>
</div>



<!-- Inactive form -->
<div *ngIf="!adminPreview && !formNotFound && adminForm.value.id && !adminForm.value.active" class="mt-4 pb-4 d-block">
  <h4>Oops</h4>
  <p>Looks like this form is no longer active.</p>
</div>



<!-- Not Found -->
<div *ngIf="formNotFound && !adminPreview" class="mt-4 pb-4 d-block text-center">
  <h4>Form Not Found</h4>
  <p>We can't find the form you were looking for.</p>
</div>


